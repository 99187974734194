@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-file-upload-button {
    display: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #e8473e;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ff564d;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 28px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}

/*---------Fin ScrollBar---------*/

body {
    font-family: 'Roboto', sans-serif !important;
    background-color: #FAFAFA !important;
    color: #444444 !important;
    font-size: 18px !important;
}

a {
    color: #0087E9;
    text-align: justify;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
}

a:hover {
    color: #39adff !important;
}

.form-control::placeholder {
    color: #cccccc;
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #e8473e;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(232 71 62 / 30%);
}

.form-select:focus {
    border-color: #e8473e;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(232 71 62 / 30%);
}

.container-simulador {
    display: flex;
    min-height: 100vh;
}

h1 {
    font-size: 2.25rem !important;
    margin-bottom: 0 !important;
    color: #444444 !important;
    font-weight: 700 !important;
}

h4 {
    margin-bottom: 0;
    color: #444444 !important;
    font-weight: 400;
}

hr {
    border-color: #888888;
}

.pt-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.pl-30 {
    padding-left: 30px;
}

.show-header {
    transform: scaleY(1) !important;
    opacity: 1 !important;
    height: auto !important;
    transition: 0.3s;
}

.show-header.hide-header {
    transform: scaleY(0) !important;
    opacity: 0 !important;
    height: 0 !important;
}

.d-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.navbar {
    padding: 20px 3px;
}

.brand-line {
    background-color: #E8473E;
    width: 125px;
    height: 5px;
    max-width: 125px;
    min-width: 125px;
    max-height: 5px;
    min-height: 5px;
    margin: auto;
}

.bg-danger {
    background-color: #E8473E !important;
}

.login-container {
    min-width: 360px;
    max-width: 460px;
}

.card {
    border: none !important;
    position: relative;
    overflow: hidden;
}

.iso-card {
    position: absolute;
    bottom: -148px;
    z-index: 0;
    right: -238px;
    z-index: 0;
}

.card-header {
    background: none !important;
    border: none !important;
    z-index: 1;
}

.card-header h4 {
    color: #444444;
}

.card-body {
    z-index: 1;
}

.info-icon {
    font-size: 1.5rem;
}

.ri-information-2-fill {
    color: #444444;
}

label {
    color: #444444;
    padding-right: 30px;
    font-size: 16px;

}

.label-btn {
    color: #444;
    text-align: right;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;

}

.label-btn-2 {
    color: #444;
    text-align: right;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;

}

.form-check-label {
    margin-top: 6px !important;
    color: #444 !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.form-check-input {
    width: 20px !important;
    height: 20px !important;
    border-radius: 5px !important;
    border: 1px solid #444 !important;
    background: #FFF !important;
}

.form-check-input[type=checkbox] {
    background-color: #fff !important;
    border-color: #e8473e !important;
}

.form-check-input[type=checkbox]:checked {
    background-color: #E8473E !important;
    border-color: #E8473E !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !important;
}

.form-check-input:focus {
    border-color: #E8473E !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(232 71 62 / 40%) !important;
}

.simulator-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0 0;
}

.btn-Primary {
    color: white !important;
    background-color: #E8473E !important;
}

.btn-Primary:hover {
    color: white !important;
    background-color: #bb2d3b !important;
    border-color: #bb2d3b !important;
}

.btn-outline-primary {
    color: #444444 !important;
    border-color: #E8473E !important;
}

.btn-outline-primary:hover {
    color: white !important;
    background-color: #E8473E !important;
}

.btn-green {
    color: white !important;
    background-color: #0099A3 !important;
    border-color: #0099A3 !important;
}

.btn-green:hover {
    color: white !important;
    background-color: #047b84 !important;
    border-color: #047b84 !important;
}

.btn:focus-visible {
    color: white !important;
    background-color: #E8473E !important;
    border-color: #E8473E !important;
    outline: 0;
    outline: none;
    box-shadow: 0 0 0 0.25rem rgb(232 71 62 / 30%);
}

.primary-mobile-btn {
    color: white !important;
    background-color: #E8473E !important;
    border-color: #E8473E !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 30px;
    font-size: 20px;
}

.secondary-mobile-btn {
    color: #999999 !important;
    background-color: #ffffff !important;
    border-color: #999999 !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 30px;
    font-size: 20px;
}

.img-contact {
    background-image: url('/assets/images/bg-contact.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.loadingLogo {
    position: absolute;
    bottom: 8px;
    filter: brightness(10);
}

.loader {
    width: 120px;
    height: auto;
}

.loader.active {
    animation: rotateAnimation 2s linear infinite;
}

.modal-content {
    overflow: hidden;
}

@keyframes rotateAnimation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/*---------Datepicker-----------*/
.calendar-btn {
    border-color: #dee2e6 !important;
    padding: 2px 10px !important;
}

.calendar-btn i {
    font-size: 20px !important;
}

.calendar-btn:hover {
    color: var(--bs-btn-hover-color) !important;
    background-color: #E8473E !important;
    border-color: #E8473E !important;
}

ngb-datepicker.dropdown-menu {
    padding: 20px !important;
}

.ngb-dp-content {
    padding-top: 0.5rem !important;
}

.ngb-dp-navigation-select {
    gap: 15px !important;
}

.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
    width: 2.5rem !important;
    height: 2.5rem !important;
}

.ngb-dp-arrow .btn-link {
    color: #E8473E !important;
}

.ngb-dp-weekday {
    color: #444444 !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.ngb-dp-header {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    padding-top: 0.5rem !important;
    background-color: #ffffff !important;
}

.ngb-dp-weekdays {
    background-color: #ffffff !important;
    border-bottom: none !important;
}

[ngbDatepickerDayView]:hover:not(.bg-primary), [ngbDatepickerDayView].active:not(.bg-primary) {
    background-color: #e8473e !important;
    outline: 1px solid #e8473e !important;
    color: white !important;
    border-radius: 30px !important;
}

[ngbDatepickerDayView] {
    width: 2.5rem !important;
    height: 2.5rem !important;
    line-height: 2.5rem !important;
    border-radius: 30px !important;
}

ngb-datepicker .bg-primary {
    color: #444444 !important;
    border-color: #E8473E !important;
    background-color: transparent !important;
    border: 1px solid;
}

.toast {
    border-color: transparent !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%23e8473e'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked {
    background-color: #ffffff !important;
    border-color: #e8473e !important;
}

.form-check-input[type=radio] {
    border-radius: 50% !important;
}

.form-check .form-check-input {
    float: left !important;
    margin-left: -1.5em !important;
}

.form-check-input {
    flex-shrink: 0 !important;
    width: 1.125em !important;
    height: 1.125em !important;
}

.btn-link {
    color: #0087E9;
    text-align: justify;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    padding: 0;
    background: none;
    border: none;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
}

.collapsed-btn {
    width: 40px;
    height: 40px;
    border: 1px solid #E8473E;
    color: #E8473E;
    border-radius: 20px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
}

.collapsed-btn.rotate-i {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);

}

/*------Modal Styles--------*/

.close-btn {
    background: none;
    border: none;
    padding: 0;
    font-size: 36px;
    line-height: 36px;
    margin-left: auto;
}

.close-btn:focus-visible {
    outline: none;
}

.modal-header {
    padding: 1.5rem;
    border-bottom: none;
}

.modal-header h4 {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 14px;
}

.modal-header .brandline {
    width: 50px;
    height: 2px;
    background-color: #E8473E;
}

.modal-body {
    padding: 0 1.5rem 1.5rem;
}

.modal-body h3 {
    font-size: 36px;
    line-height: normal;
    font-weight: 700;
}

.modal-body .brandline {
    width: 120px;
    height: 2px;
    background-color: #E8473E;
    margin: auto;
}

.modal-body p.content-placeholder {
    font-size: 20px;
    line-height: normal;
}

.modal-footer {
    padding: 1.5rem;
}

.FixedActionButtons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    /* O el color que necesites */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    /* Sombra superior para destacar */
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    /* O "center", según los botones */
    align-items: center;
    z-index: 1000;
    /* Asegura que esté sobre otros elementos */
}

/* Estilos para dispositivos más pequeños, como móviles */
@media (max-width: 767px) {
    .modal-dialog {
        width: 100%;
        margin: 0;
        padding: 15px;
    }

    .login-container {
        min-width: 100%;
    }
}

/* Estilos para dispositivos más grandes, como desktops */
@media (min-width: 768px) {
    .modal-dialog {
        max-width: 900px;
        width: 80%;
    }

    .modal-header {
        padding: 1rem;
    }

    .modal-body {
        padding: 0 2rem 2rem;
    }

    .modal-footer {
        padding: 2rem;
    }
}

/*---------Tooltip-----------*/
.tooltip.show {
    opacity: 1;
}

.confuturo-tooltip .tooltip {
    margin-bottom: 0;
}

.tooltip-inner {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 14px;
    color: white;
    background-color: #444444;
    padding: 12px;
}

.tooltiplargo .tooltip-inner {
    max-width: 500px !important;
    width: 500px !important;
}


/*---------------Validation inputs messages----------*/
.validation-messages {
    position: absolute;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    margin-top: 5px;
}

/*---------------Animations--------------*/

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

.pulseAnimation {
    width: 24px;
    height: 24px;
    animation: scaleAnimation 2s infinite;
}


/*---------------Responsive Design--------------*/

@media (max-width: 576px) {

    .tooltiplargo .tooltip-inner {
        max-width: 250px !important;
        width: 250px !important;
    }
    ol, ul {
        padding-left: 1rem;
    }
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
    .navbar {
        padding: 20px;
    }
}

@media (min-width: 1200px) {}